<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0">
                                <router-link to="/message/inbox"><a href=""><i class="fa fa-arrow-left mr-3 text-dark"></i></a></router-link>
                                {{ tt('detail_message') }}
                            </h3>
                        </div>
                        <div class="col text-right">
                            <base-button size="sm" type="default" @click="reply">{{ tt('reply_message') }}</base-button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="media align-items-center">
                          <span class="avatar avatar-sm rounded-circle">
                            <img alt="Image placeholder" src="http://cb8ea0e1d9f4.ngrok.io/images/user/programmer.jpg">
                          </span>
                          <div class="media-body ml-2">
                            <span class="mb-0 text-sm font-weight-bold ml-1">Administrator</span><br>
                            <a class="text-sm" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="ml--2 mt--2">
                                    <base-dropdown class="nav-item" tag="li" title-classes="nav-link" title-tag="a" :title="tt('to')" menu-classes="dropdown-menu-center py-0 overflow-hidden">
                                        <template>                         
                                          <div class="mt-3 ml-3">
                                            {{ tt('from') }} : Administrator
                                            {{ tt('to') }} : Administrator
                                          </div>
                                        </template>
                                    </base-dropdown>
                                </div>
                            </a>
                          </div>
                          <p style="font-size: 13px"><i class="fa fa-calendar mr-2" aria-hidden="true"></i>2020-08-24 15:18:35</p>
                        </div>
                        <div class="ml-1">
                            <p class="text-dark" style="font-size: 13px; margin-left: 45px">xx</p>
                        </div>
                        <hr>
                    </div>

                    <div>
                        <div class="media align-items-center">
                          <span class="avatar avatar-sm rounded-circle">
                            <img alt="Image placeholder" src="http://cb8ea0e1d9f4.ngrok.io/images/user/programmer.jpg">
                          </span>
                          <div class="media-body ml-2">
                            <span class="mb-0 text-sm font-weight-bold ml-1">Administrator</span><br>
                            <a class="text-sm" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="ml--2 mt--2">
                                    <base-dropdown class="nav-item" tag="li" title-classes="nav-link" title-tag="a" :title="tt('to')" menu-classes="dropdown-menu-center py-0 overflow-hidden">
                                        <template>                         
                                          <div class="mt-3 ml-3">
                                            {{ tt('from') }} : Administrator
                                            {{ tt('to') }} : Administrator
                                          </div>
                                        </template>
                                    </base-dropdown>
                                </div>
                            </a>
                          </div>
                          <p style="font-size: 13px"><i class="fa fa-calendar mr-2" aria-hidden="true"></i>2020-08-24 15:18:35</p>
                        </div>
                        <div class="ml-1">
                            <p class="text-dark" style="font-size: 13px; margin-left: 45px">xx</p>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="formReply.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('reply_message') }}</h5>
            </template>
            <div>
                <base-input>
                    <textarea class="form-control" id="exampleFormControlTextarea3" rows="10" :placeholder="tt('message')"></textarea>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                <base-button type="primary">{{ tt('reply') }}</base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    export default {        
        data() {
            return {                
                formReply: {
                    show: false
                },      
                table: {
                    data: [
                        {
                            id: 1,
                            sender: "Administrator", 
                            subject: "Hallo",
                            message: "Hallo gess", 
                            date: "2020-08-24 15:18:35", 
                        },
                        {
                            id: 2,
                            sender: "Administrator", 
                            subject: "Test",
                            message: "Test nih", 
                            date: "2020-08-24 15:18:35", 
                        },
                        {
                            id: 1,
                            sender: "Administrator", 
                            subject: "Selamat pagi",
                            message: "Selamat pagi semua", 
                            date: "2020-08-24 15:18:35", 
                        },
                    ]
                },        
                inbox: {
                    
                }  
            }
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            reply() {
                this.formReply.show = true;
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        this.alertDialog('success', this.tt('success_delete'));
                    } else {
                        this.alertDialog('error', this.tt('error_delete'));
                    }
                })
            },
        }   
    };
</script>
<style></style>
